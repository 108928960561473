/* eslint-disable max-len */
/* eslint-disable prefer-template */
import {Box, Chip, Divider, IconButton, Typography} from '@mui/material'
import {GoogleMap, Marker, MarkerClusterer, MarkerF} from '@react-google-maps/api'
import {Link} from 'react-router-dom'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {AccountCircle, ArrowBackIos, ArrowForwardIos, Close, LocationOn} from '@mui/icons-material'
import marker from '../../../../images/marker.png'
import school from '../../../../images/school.png'
import teacher from '../../../../images/teacher.png'
import MyDiv from './ mapViewTab.style'

const OPTIONS = {
  streetViewControl: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}],
    },
  ],
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -170,
      east: 170,
    },
    strictBounds: true,
  },
}

export default function MapViewTab(props) {
  const {t} = useTranslation()
  const elloMate = (...args) => {
    console.log(...args)
  }

  return (
    <MyDiv>
      <Box className="h-100">
        <Box className="map_container">
          <GoogleMap
            center={props.center}
            onLoad={props.onLoad}
            onClick={props.handleMarkerPosition}
            onBoundsChanged={props.handleMapPosition}
            mapContainerStyle={props.containerStyle}
            zoom={2}
            options={OPTIONS}
          >
            <Marker position={props.marker} icon={marker} />
            <MarkerClusterer options={{minimumClusterSize: 3}} gridSize={60}>
              {(cluster) => props.filteredTeachers?.map(
                (item, index) => {
                  return (
                    <>
                      <MarkerF
                        options={{
                          icon: {
                            url: item.account_type === 2
                              && school ||
                              item.account_type === 1 && teacher,
                          },
                        }}
                        onClick={props.handleActiveMarker(item.teacher_id)}
                        key={index}
                        position={{
                          lat: parseFloat(item.latitude),
                          lng: parseFloat(item.longitude),
                        }}
                        clusterer={cluster}
                      />
                      {elloMate(props.theCurrentTeacher, +props.theCurrentTeacher?.latitude, +item.latitude, +props.theCurrentTeacher?.longitude, +item.longitude, index)}
                      {props.theCurrentTeacher && +props.theCurrentTeacher?.latitude ===
                        +item.latitude &&
                        +props.theCurrentTeacher?.longitude ===
                        +item.longitude && index === 0 ? <Box className="list_users">
                          <IconButton className="btn_hide" onClick={() => props.setCurrentTeacher(null)}><Close /></IconButton>
                          <Link style={{textDecoration: 'none'}} target="_blank"
                            to={'/teacher/' + props.theCurrentTeacher?.name + props.theCurrentTeacher?.surname + props.theCurrentTeacher?.teacher_id}
                          >
                            <Box className="inner_list">
                              {props.theCurrentTeacher?.account_type === 2 ?
                                props.theCurrentTeacher?.school_image?.includes('storage/upload') ? <img src={props.theCurrentTeacher?.school_image} /> : <Typography className="avatar_cam title_cam" >{props.theCurrentTeacher?.school_name?.substring(0, 1).toUpperCase()}</Typography> :
                                props.theCurrentTeacher?.profile_image?.includes('storage/upload') ? <img src={props.theCurrentTeacher?.profile_image} /> : <Typography className="avatar_cam title_cam" >{props.theCurrentTeacher?.name?.substring(0, 1).toUpperCase()}{props.theCurrentTeacher?.surname?.substring(0, 1).toUpperCase()}</Typography>}
                              <Box className="list_items">
                                <Typography className="username">
                                  {props.theCurrentTeacher?.account_type === 2 ? props.theCurrentTeacher?.school_name : props.theCurrentTeacher?.name + ' ' + props.theCurrentTeacher?.surname}
                                </Typography>
                                {item?.is_doc_verified === 1 ? <Typography className="verified_text">
                                  Verified
                                </Typography> : null}
                                {props.theCurrentTeacher?.experience_name === null ? <Typography className="list_data">
                                  0 - 1 years of experience
                                </Typography>
                                  : <Typography className="list_data">
                                    {props.theCurrentTeacher?.experience_name}+ years of experience
                                  </Typography>}
                              </Box>
                            </Box>
                            <Box className="category_box">
                              {props.theCurrentTeacher?.sub_category_name === null ?
                                <Chip
                                  label={t('Nodatayet')}
                                  className="category_items"
                                /> : <Chip
                                  label={props.theCurrentTeacher?.sub_category_name}
                                  className="category_items"
                                />}
                            </Box>
                            <Divider />
                            <Box className="flex_box">
                              <Box className="list_row">
                                <LocationOn />
                                <Typography className="list_data">
                                  {props.theCurrentTeacher?.address}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className="flex_box">
                              <Box className="list_row2" sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Box className="flex_child">
                                  <AccountCircle />
                                  <Typography className="view_btn">{t('ViewProfile')}</Typography>
                                </Box>
                                <Box className="arrow_icn">
                                  <ArrowForwardIos />
                                </Box>
                              </Box>
                            </Box>
                          </Link>
                          {(props.hasNext || props.hasPrevious) ?
                            <Box className="icon_box"><IconButton
                              className={props.hasPrevious ? 'icn_btn' : 'icn_btn2'}
                              variant="text"
                              disabled={!props.hasPrevious}
                              onClick={props.handlePreviousClick}
                              color="primary"
                            >
                              <ArrowBackIos />
                            </IconButton><IconButton
                              className={props.hasNext ? 'icn_btn' : 'icn_btn2'}
                              variant="text"
                              disabled={!props.hasNext}
                              onClick={props.handleNextClick}
                              color="primary"
                            >
                              <ArrowForwardIos />
                            </IconButton></Box>
                            : null}
                        </Box> : null
                      }
                    </>
                  )
                }
              )}
            </MarkerClusterer>
          </GoogleMap>
        </Box>
      </Box>
    </MyDiv>
  )
}
